require("@rails/ujs").start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require('jquery')
import 'jquery-validation'
import './../js/validation'

import './../js/jquery-form'
import 'bootstrap/dist/js/bootstrap'
import './../js/pagy.js.erb'
import 'magnific-popup/dist/jquery.magnific-popup'
import 'magnific-popup/dist/magnific-popup.css'
import 'jquery-datetimepicker/build/jquery.datetimepicker.full'
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css'

// import css
import './../css/application'
